<template>
    <UModal v-model="open" size="lg" :ui="{ width: 'w-full sm:max-w-2xl', }">
        <div class="rounded-lg overflow-hidden">
            <div class="relative p-8 bg-blue-dark-950 flex items-center justify-between text-white mb-4">
                <span>
                    {{ t('serviceRequestForm') }}/ <span class="text-my-green">
                        {{ selected.title }}
                    </span>
                </span>
                <UIcon name="ri:close-fill" size="24" @click="open = false"></UIcon>
            </div>
            <form class="p-8" @submit.prevent="sendRequest">
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">{{ t('name') }}</div>
                        <input id="ticket" type="text" v-model="name"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            required placeholder="">
                    </label>

                    <label for="ticket" class=" block">
                        <div class=" mb-2 text-[14px]/[17px] inter">{{ t('surname') }}</div>
                        <input id="ticket" type="name" required v-model="lastname"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="">
                    </label>
                </div>
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">{{ t('ticketInfo') }}</div>
                        <input id="ticket" type="text" required v-model="ticket"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="">
                    </label>
                    <ApplicationServiceRequestDatePicker :label="t('flightDate')" v-model="flightDate" />
                </div>
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">{{ t('flightNumber') }}</div>
                        <input id="ticket" type="text" required v-model="flightNumber"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="">
                    </label>
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">{{ t('phoneNumber') }}</div>
                        <input id="ticket" type="tel" required v-model="phoneNumber"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="">
                    </label>
                </div>
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">{{ t('Email') }}</div>
                        <input id="ticket" type="email" v-model="email"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter invalid:text-red-500 invalid:border-red-500"
                            placeholder="">
                    </label>
                </div>
                <div>
                    <button :disabled="disableButton"
                        class="py-4 px-6 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green disabled:opacity-50">
                        {{ t('leaveApplication') }}
                    </button>
                </div>
            </form>
        </div>
    </UModal>
</template>
<script setup>
const { locale, t } = useI18n()
const props = defineProps(['selected'])
const toast = useToast()
const open = ref(false)
function openModal() {
    open.value = true
}
const rc = useRuntimeConfig()
const headers = computed(() => ({
    lang: locale.value
}))

const name = ref();
const lastname = ref();
const ticket = ref();
const flightNumber = ref();
const flightDate = ref()
const phoneNumber = ref()
const email = ref()
const disableButton = ref(false)
async function sendRequest() {
    try {
        if (!flightDate.value) {
            toast.add({
                title: 'Choose flight date!',
                color: 'red'
            })
        }
        const email =/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        const phone  = /^[\+\d\(\)]*$/
        if (!phone.test(phoneNumber.value) || phoneNumber.value.trim().length<9) {
            return toast.add({
                title: t('phoneAlert'),
                color: 'red'
            })
        }
        if (email.value && !email.test(email.value)) {
            return toast.add({
                title: t('emailAlert'),
                color: 'red'
            })
        }
        disableButton.value = true;
        // props.selected.form_type
        const res = await $fetch(rc.public.api + `/api/application/application`, {
            method: 'POST',
            body: {
                "name": name.value,
                "surname": lastname.value,
                "ticket_number": ticket.value,
                "departure_date": flightDate.value.toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }),
                "flight_number": flightNumber.value,
                "service_id": props.selected.id,
                phone: phoneNumber.value,
                email: email.value,
            },
            headers: headers.value
        })
        toast.add({
            title: res.data.message,
            color: 'green'
        })
        disableButton.value = false
        open.value = false
        name.value = '';
        lastname.value = '';
        ticket.value = '';
        flightNumber.value = '';
        flightDate.value = null;

    } catch (error) {
        toast.add({
            title: error?.message,
            color: 'red'
        })
    }
}
defineExpose({
    openModal
})
</script>