<template>
    <label for="ticket" class=" block">
        <div class=" mb-2 text-[14px]/[17px] inter">{{ label }}</div>
        <div class=" w-full p-4 pb-3 rounded-xl" :class="secondary ? 'border bg-white' : 'border-2 border-gray-line'">
            <UPopover :popper="{ placement: 'bottom-start' }" :disabled v-model:open="isOpen">
                <button type="button" class="flex items-center gap-4">
                    <Icon name="hugeicons:calendar-03" size="20" />
                    <span class="text-[14px]">
                        {{ model ? format(model) : label }}
                    </span>
                </button>
                <template #panel="{ close }">
                    <DatePicker v-model="model" @close="close" :required="true" />
                </template>
            </UPopover>
        </div>
    </label>
</template>

<script setup>

const model = defineModel()
const props = defineProps({
    icon: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false
    },
    secondary: {
        type: Boolean,
        default: false
    }
})
function format(d) {
    return d?.toLocaleDateString?.('ru')
}
const isOpen = ref(false)
</script>